var dropdownOpen = document.querySelector(".dropdown-em");
var dropdownContent = document.querySelector(".dropdown-content");
var dropdowTitle = document.querySelector(".dropdown-title");

dropdowTitle.addEventListener("click", function () {
    dropdownOpen.classList.toggle("active");
    if (dropdownOpen.classList.contains("active")) {
        dropdownContent.style.height = "460px";
    } else {
        dropdownContent.style.height = "0px";
    }
});

function Marquee(selector, speed) {
    const parentSelector = document.querySelector(selector);
    const clone = parentSelector.innerHTML;
    const firstElement = parentSelector.children[0];
    let i = 0;
    parentSelector.insertAdjacentHTML("beforeend", clone);
    parentSelector.insertAdjacentHTML("beforeend", clone);

    setInterval(function () {
        firstElement.style.marginLeft = `-${i}px`;
        if (i > firstElement.clientWidth) {
            i = 0;
        }
        i = i + speed;
    }, 0);
}

//after window is completed load
//1 class selector for marquee
//2 marquee speed 0.2
window.addEventListener("load", Marquee(".truss-marquee", 0.6));

var cookieToast = document.getElementById("cookieToast");

window.addEventListener("load", () => {
    setTimeout(function () {
        const toastBootstrap = bootstrap.Toast.getOrCreateInstance(cookieToast);
        toastBootstrap.show();
    }, 3000);
});

(function () {
    function updateClock(clockId, timeZone) {
        const now = new Date();
        const localTime = now.toLocaleString("en-US", {
            timeZone: timeZone,
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });

        const clockElement = document.getElementById(clockId);

        // Split the localTime string into components
        const [hour, minute, period] = localTime.split(/:| /);

        // Display each time component separately
        clockElement.innerHTML = `
            <span class="hour">${hour}</span>
            <span class="blink">:</span>
            <span class="minute">${minute}</span>
            <span class="period">${period}</span>
        `;
    }

    function updateClocks() {
        updateClock("usaClock", "America/New_York"); // USA Eastern Time
        updateClock("ukClock", "Europe/London"); // UK Time
        updateLocalClock(); // User's local time
    }

    function updateLocalClock() {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        updateClock("localClock", userTimeZone);
    }

    // Update the clocks initially and every second
    updateClocks();
    setInterval(updateClocks, 1000); // Update every second
})();
